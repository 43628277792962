import { Pipe, PipeTransform } from '@angular/core';
export const COLOR_VALUES = [
  '#DC2626,#FEE2E2', // rouge
  '#EA580C,#FFEDD5', // orange
  '#D97706,#FEF3C7', // sable
  '#CA8A04,#FEF9C3', // or
  '#65A30D,#ECFCCB', // vert pomme
  '#16A34A,#DCFCE7', // vert menthe
  '#0891B2,#CFFAFE', // vert turquoise
  '#2563EB,#DBEAFE', // blue
  '#7C3AED,#EDE9FE', // violet
  '#C026D3,#FAE8FF', // mauve
  '#DB2777,#FCE7F3' // rose
];
@Pipe({
  name: 'colors'
})
export class ColorsPipe implements PipeTransform {
  public transform(value: string, type?: string): unknown {
    if (value) {
      if (type === 'bg') return value.split(',')[1];
      return value.split(',')[0];
    }
    return '';
  }
}
