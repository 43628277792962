import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { map, of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

import { SignInJwtService } from '../dataService/signIn-jwt.service';

@Injectable({
  providedIn: 'root'
})
export class IsSignedInGuard implements CanActivate {
  constructor(private router: Router, protected signInService: SignInJwtService) {}

  public canActivate(): Observable<boolean> {
    if (this.signInService.isConnecting) {
      return this.signInService.connectedObs.pipe(
        map((connected) => {
          if (!this.signInService.isConnected) {
            void this.router.navigate(['login']);
          }
          return connected;
        })
      );
    } else {
      if (!this.signInService.isConnected) {
        void this.router.navigate(['login']);
      }

      return of(this.signInService.isConnected);
    }
  }
}
