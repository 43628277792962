import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

import { MatCheckboxModule } from '@angular/material/checkbox';

import { UserClientGroupFormAutoCompleteModule } from '../../chipList/UserClientGroup/UserClientGroup.module';

import { CopywriterStyleRecordFormAutoCompleteModule } from '../../chipList/CopywriterStyleRecord/CopywriterStyleRecord.module';

import { UserClientInvitationFormComponent } from './UserClientInvitation.component';
@NgModule({
  declarations: [UserClientInvitationFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,

    MatDatepickerModule,
    MatNativeDateModule,

    MatCheckboxModule,

    UserClientGroupFormAutoCompleteModule,

    CopywriterStyleRecordFormAutoCompleteModule,

    PipesModule
  ],
  exports: [
    UserClientInvitationFormComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatSelectModule,

    MatDatepickerModule,

    MatCheckboxModule,

    UserClientGroupFormAutoCompleteModule,

    CopywriterStyleRecordFormAutoCompleteModule,

    PipesModule
  ]
})
export class UserClientInvitationFormModule {}
