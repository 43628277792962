import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HtmlScapePipe } from './html-scape.pipe';
import { ColorsPipe } from './colors.pipe';
import { FileTypePipe } from './file-extension.pipe';

@NgModule({
  declarations: [HtmlScapePipe, ColorsPipe, FileTypePipe],
  imports: [CommonModule],
  exports: [HtmlScapePipe, ColorsPipe, FileTypePipe]
})
export class PipesModule {}
