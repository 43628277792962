export const environment = {
  production: true,
  application_instance: '653b8ddc2e1023eadf0e00f7',
  data: {
    authServiceUrl: 'api/authentication/',
    api: 'api/',
    ui: {
      UseEmailInvitations: true
    }
  }
};
