import { Injectable } from '@angular/core';

import { RunTimeDataService } from '@leandredev/common-ng16/infraService';
import { environment } from 'src/environments/environment';

interface I_Config {
  [key: string]: any;
}
declare const daesignConfig: I_Config;
@Injectable({
  providedIn: 'root'
})
export class PublicConfService {
  config: I_Config;

  constructor(runTimeDataService: RunTimeDataService) {
    try {
      this.config = daesignConfig;
    } catch (error) {
      console.warn('%cSTART APPLICATION ONLY WITH  ENVIRONMENT FILE', 'background: #222; color: #FFFFFF');
    }

    if (this.config) {
      Object.assign(environment, this.config);
    }
    runTimeDataService.patchStore('environment', environment);
    runTimeDataService.patchStore('publicConf', environment.data);
  }
}
