import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileType'
})
export class FileTypePipe implements PipeTransform {
  public transform(fileNameOrUrl: string, extractFilename = false): string {
    if (!fileNameOrUrl) return 'No url found !';

    // Si c'est une URL, extraire le nom de fichier de l'URL
    const urlParts = fileNameOrUrl.split('/');
    const lastPart = urlParts[urlParts.length - 1];
    const fileName = lastPart.split('?')[0]; // Enlever les paramètres de requête s'ils existent

    if (extractFilename) {
      // cas d'une url qui se termine par /
      if (!lastPart) {
        return urlParts[urlParts.length - 2];
      }

      // cas d'une url qui se termine par du texte ou un ID
      return fileName;
    }

    // Dans ce cas fileNameOrUrl fini par un / donc il s'agit probablement d'une URL
    if (fileName === '') {
      return 'Web page';
    }
    // Obtenez l'extension du fichier
    const extension = fileName.split('.').pop()?.toLowerCase();

    switch (extension) {
      case 'doc':
      case 'docx':
        return 'Word document';
      case 'pptx':
        return 'PowerPoint slides';
      case 'pdf':
        return 'PDF document';
      case 'png':
      case 'jpeg':
      case 'jpg':
      case 'webp':
        return 'Image';
      case 'csv':
        return 'CSV File';
      case 'xlsx':
        return 'Excel File';
      case 'html':
        return 'Web page';
      case 'txt':
        return 'Text';
      default:
        return 'Web page';
    }
  }
}
