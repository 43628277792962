import { Injectable } from '@angular/core';
import { Adaptater } from '@leandredev/common-ng16/components';
import { api_collection_plateform } from '@leandredev/adaptivai-ng16-lib/httpClient/services/api';
import { Observable } from 'rxjs';

import { DataService } from './dataService.service';

@Injectable({
  providedIn: 'root'
})
export class DataServiceAdapterService extends Adaptater {
  constructor(protected dataService: DataService) {
    super();
  }

  fsoUrlResolver(): Observable<string> {
    return; // of(this.dataService.uploadUrl);
  }

  getProxyData(colName: keyof api_collection_plateform, idRef: string): Observable<string> {
    return this.dataService.getProxyData(colName, idRef);
  }
}
