<!--<fury-page fxLayoutGap="16" class="dashboard" fxLayout="column">
  
  <h2>Bienvenue dans {{ appName }}</h2>
  <a routerLink="/data/Act/list/" class="item-container mat-elevation-z1 border-radius">
    <div class="big-button-icon">
      <mat-icon color="primary">dashboard</mat-icon>
      <div class="explainer">
        <h3>Schémas</h3>
        <p>Cette partie vous permet de décrire vos schémas bases de données et créer ou modifier de nouveaux schémas MongoDB.</p>
      </div>
    </div>
  </a>
  <a routerLink="/data/NaturalPerson/list/" class="item-container mat-elevation-z1 border-radius">
    <div class="big-button-icon">
      <mat-icon color="primary">view_headline</mat-icon>
      <div class="explainer">
        <h3>Collections</h3>
        <p>A la façon d'un CMS vous pouvez administrer tout vos Datasets avec les opérations de base CRUD.</p>
      </div>
    </div>
  </a>
</fury-page>
-->
<div class="container">
  <ng-container *ngFor="let item of items">
    <div class="item-container">
      <div class="big-button-icon mat-elevation-z1 border-radius" [routerLink]="item.link">
        <div>
          <mat-icon color="primary">{{ item.icon }}</mat-icon>
        </div>
        <div class="explainer">
          <h3>{{ item.title }}</h3>
          <p>{{ item.subtitle }}</p>
        </div>
      </div>
    </div>
  </ng-container>
</div>
