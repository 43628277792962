<div class="sidenav" fxLayout="column">
  <div class="sidenav-toolbar" fxLayout="row" fxLayoutAlign="start center">
    <div [routerLink]="['/']" class="sidenav-toolbar-logo">
      <div class="logo fill-primary-contrast">
        <img src="./assets/img/logos/logo-dark.png" />
      </div>
    </div>

    <span fxFlex><!-- fill space --></span>

    <!--<div fxFlexAlign="center" fxHide fxShow.gt-md>
      <button (click)="toggleCollapsed()" class="sidenav-toolbar-collapse-toggle" mat-icon-button>
        <mat-icon *ngIf="!collapsed" class="sidenav-toolbar-collapse-icon">radio_button_checked</mat-icon>
        <mat-icon *ngIf="collapsed" class="sidenav-toolbar-collapse-icon">radio_button_unchecked</mat-icon>
      </button>
    </div>-->
    <!-- <button (click)="openQuickPanel.emit()">CHAT</button> -->
  </div>
  <fury-scrollbar class="sidenav-items" fxFlex="auto">
    <div *ngIf="sidenavUserVisible$ | async" class="sidenav-user">
      <app-user-avatar [size]="'big'"></app-user-avatar>

      <h3 class="sidenav-user-name" *ngIf="profile">{{ profile.name }}</h3>
      <h4 class="sidenav-user-email" *ngIf="profile">{{ profile.email }}</h4>
      <button type="button" color="primary" mat-raised-button (click)="logout()">logout</button>
    </div>

    <fury-sidenav-item *ngFor="let item of items$ | async" [item]="item" [level]="0"></fury-sidenav-item>
  </fury-scrollbar>
</div>
