import { Component } from '@angular/core';

@Component({
  selector: 'fury-quickpanel',
  templateUrl: './quickpanel.component.html',
  styleUrls: ['./quickpanel.component.scss']
})
export class QuickpanelComponent {
  todayDay: string;
  todayDate: string;
  todayDateSuffix: string;
  todayMonth: string;
}
