import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { interfaces } from '@leandredev/adaptivai-common-lib';

@Component({
  selector: 'fury-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  styleUrls: ['./toolbar-user.component.scss']
})
export class ToolbarUserComponent implements OnInit {
  @Input()
  public profile: interfaces.IUserAdmin;
  public profileEditUrl = '';
  isOpen: boolean;

  public userName = '';

  constructor(protected router: Router) {}

  ngOnInit() {
    if (this.profile) {
      this.userName = `${this.profile.firstName} ${this.profile.lastName}`;
      this.profileEditUrl = `/data/UserAdmin/edit/${this.profile._id}`;
    }
  }

  toggleDropdown(): void {
    this.isOpen = !this.isOpen;
  }

  onClickOutside(): void {
    this.isOpen = false;
  }

  onclickProfileEdit(): void {
    void this.router.navigate([this.profileEditUrl]);
  }
}
