<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-item" *ngIf="!unVisibleComp['firstName']">
    <mat-form-field appearance="fill">
      <mat-label>firstName</mat-label>
      <input matInput placeholder="" formControlName="firstName" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['lastName']">
    <mat-form-field appearance="fill">
      <mat-label>lastName</mat-label>
      <input matInput placeholder="" formControlName="lastName" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['email']">
    <mat-form-field appearance="fill">
      <mat-label>email</mat-label>
      <input matInput placeholder="" formControlName="email" />
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['createdAt']">
    <mat-form-field appearance="fill">
      <mat-label>createdAt</mat-label>
      <input matInput placeholder="" [matDatepicker]="mycreatedAt" formControlName="createdAt" />
      <mat-datepicker-toggle matSuffix [for]="mycreatedAt"></mat-datepicker-toggle>
      <mat-datepicker #mycreatedAt></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['userClientGroup']">
    <app-userclientgroup-form-auto-complete [formArr]="formGroup['_userClientGroup']" placeholder="userClientGroup">
    </app-userclientgroup-form-auto-complete>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['copywriterSetting']">
    <app-copywriterstylerecord-form-auto-complete [formCtrl]="formGroup['_copywriterSetting']" placeholder="copywriterSetting">
    </app-copywriterstylerecord-form-auto-complete>
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['categoryEditor']">
    <mat-checkbox formControlName="categoryEditor">categoryEditor</mat-checkbox><br />
  </div>

  <div class="form-item" *ngIf="!unVisibleComp['tagEditor']"><mat-checkbox formControlName="tagEditor">tagEditor</mat-checkbox><br /></div>

  <div class="form-item" *ngIf="!unVisibleComp['documentUpload']">
    <mat-checkbox formControlName="documentUpload">documentUpload</mat-checkbox><br />
  </div>
</div>
