import { Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'fury-toolbar-search',
  templateUrl: './toolbar-search.component.html',
  styleUrls: ['./toolbar-search.component.scss']
})
export class ToolbarSearchComponent {
  isOpen: boolean;

  @ViewChild('input', { read: ElementRef, static: true }) input: ElementRef;

  open(): void {
    this.isOpen = true;

    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      this.input.nativeElement.focus();
    }, 100);
  }

  close(): void {
    this.isOpen = false;
  }
}
