import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import isFunction from 'lodash-es/isFunction';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SidenavService } from '../sidenav.service';
import { SidenavItem } from './sidenav-item.interface';

/* eslint-disable @typescript-eslint/ban-types */
@Component({
  selector: 'fury-sidenav-item',
  templateUrl: './sidenav-item.component.html',
  styleUrls: ['./sidenav-item.component.scss'],
  animations: [
    trigger('dropdownOpen', [
      state(
        'false',
        style({
          height: 0
        })
      ),
      state(
        'true',
        style({
          height: '*'
        })
      ),
      transition('false <=> true', animate('300ms cubic-bezier(.35, 0, .25, 1)'))
    ])
  ]
})
export class SidenavItemComponent implements OnInit {
  @Input() item: SidenavItem;
  @Input() level: number;
  public badgeValue: string;

  isCollapsed$ = this.sidenavService.collapsed$;
  dropdownOpen$: Observable<boolean>;

  constructor(private sidenavService: SidenavService, private router: Router) {
    this.dropdownOpen$ = this.sidenavService.currentlyOpen$.pipe(
      map((currentlyOpen) => this.item.subItems && this.item.subItems.length > 0 && currentlyOpen.indexOf(this.item) > -1)
    );
  }
  ngOnInit(): void {
    this.sidenavService.badgesData.subscribe((badgesData) => {
      if (this.item['badgeId'] != undefined && badgesData[this.item.badgeId]) {
        this.badgeValue = badgesData[this.item.badgeId];
        if (this.badgeValue == '0') {
          this.badgeValue = null;
        }
      }
    });
  }

  get levelClass(): string {
    return `level-${this.level}`;
  }

  isFunction(routeOrFunction: string[] | Function): any {
    return isFunction(routeOrFunction);
  }

  handleClick(): void {
    if (this.item.subItems && this.item.subItems.length > 0) {
      this.sidenavService.toggleItemOpen(this.item);
    } else if (typeof this.item.routeOrFunction === 'string' || this.item.routeOrFunction instanceof String) {
      void this.router.navigate([this.item.routeOrFunction]);
    } else if (typeof this.item.routeOrFunction === 'function' || this.item.routeOrFunction instanceof Function) {
      this.item.routeOrFunction();
    } else {
      throw Error('Could not determine what to do, Sidenav-Item has no routeOrFunction set AND does not contain any subItems');
    }
  }

  getTextIcon(item: SidenavItem): string {
    let result = '';

    if (item) {
      const name = item.name.split(' ');

      if (name.length > 0) {
        result += name[0].charAt(0).toUpperCase();
      }

      if (name.length > 1) {
        result += name[1].charAt(0).toLowerCase();
      }

      if (name.length === 1) {
        result += name[0].charAt(1).toLowerCase();
      }
    }

    return result;
  }
}
