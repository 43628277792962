/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { interfaces } from '@leandredev/adaptivai-common-lib';
import { DataService } from 'src/app/dataService/dataService.service';
import { mergeMap, map, startWith } from 'rxjs/operators';
import { AutoCompleteComponent } from '@leandredev/common-ng16/components';

@Component({
  selector: 'app-userclientgroup-form-auto-complete',
  templateUrl: './UserClientGroup.component.html',
  styleUrls: ['./UserClientGroup.component.scss']
})
export class UserClientGroupFormAutoCompleteComponent extends AutoCompleteComponent<interfaces.IUserClientGroup> implements OnInit, OnDestroy {
  private _filterQuery = '';
  @Input()
  public set filterQuery(value: string) {
    this._filterQuery = value;
  }
  public get filterQuery(): string {
    return this._filterQuery;
  }

  @Input()
  public get hasOverridedData(): boolean {
    return this._hasOverridedData;
  }
  public set hasOverridedData(value: boolean) {
    this._hasOverridedData = value;
    if (!value) {
      return;
    }
    this.unsubscribePformCtrl();
    if (this.overridedData) {
      this.filteredData = this.overridedData;
    }
  }
  private _hasOverridedData = false;

  @Input()
  public get overridedData(): interfaces.IUserClientGroup[] {
    return this._overridedData;
  }
  public set overridedData(value: interfaces.IUserClientGroup[]) {
    this._overridedData = value;
    if (!value || !this.hasOverridedData) {
      return;
    }
    this.filteredData = value;
  }
  private _overridedData: interfaces.IUserClientGroup[] = [];

  constructor(protected dataService: DataService) {
    super();
    this.httpServiceBase = dataService.api.collections.UserClientGroup;

    if (this.hasOverridedData) {
      return;
    }
    this.pformCtrlSub = this._formCtrl.valueChanges
      .pipe(
        startWith(null),
        mergeMap((value) => {
          let query = '';
          const limit = `30/$limit/`;
          if (typeof value === 'string') {
            if (value) {
              value = value.trim();
              query = `name/${value}/i/$regex`;
            } else {
              query = '*';
            }
          } else {
            query = '*';
          }
          if (this.filterQuery) {
            query = `${query}/${this.filterQuery}`;
          }

          return this.httpServiceBase.get(`${query}/${limit}`);
        }),

        map((result) => {
          if (!this.pformCtrlSub) {
            return;
          }
          if (this.hasOverridedData) {
            this.unsubscribePformCtrl();
            this.filteredData = this.overridedData;
            return;
          }
          this.filteredData = result.response;
        })
      )
      .subscribe();
  }

  private unsubscribePformCtrl(): void {
    if (!this.pformCtrlSub) {
      return;
    }
    this.pformCtrlSub.unsubscribe();
    // delete this.pformCtrlSub;
  }
}
