import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MaterialModule } from '../../../@fury/shared/material-components.module';
import { ScrollbarModule } from '../../../@fury/shared/scrollbar/scrollbar.module';
import { PipesModule } from '../../pipes/pipes.module';
import { SidenavItemComponent } from './sidenav-item/sidenav-item.component';
import { SidenavComponent } from './sidenav.component';
import { SidenavNavigationData } from './sidenav.data';
import { SidenavService } from './sidenav.service';

@NgModule({
  imports: [CommonModule, RouterModule, MaterialModule, ScrollbarModule, PipesModule],
  declarations: [SidenavComponent, SidenavItemComponent],
  exports: [SidenavComponent],
  providers: [SidenavNavigationData, SidenavService]
})
export class SidenavModule {}
