<mat-form-field class="chip-list">
  <mat-chip-grid #chipGrid>
    <mat-chip-row
      *ngFor="let obj of populatedValue"
      [removable]="removable"
      (removed)="remove(obj)"
      [disabled]="formCtrl?.disabled || formArr?.disabled || false"
    >
      {{ obj.name }}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip-row>

    <input
      [placeholder]="placeholder"
      #UserInput
      [formControl]="_formCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipGrid"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="addOnBlur"
      (matChipInputTokenEnd)="add($event)"
      [disabled]="formCtrl?.disabled || formArr?.disabled || false"
    />
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let obj of filteredData" [value]="obj">
      {{ obj.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
