import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ObjectId } from '@leandredev/adaptivai-common-lib';

@Component({
  selector: 'fury-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  private static isInitialLoad = true;
  gap = 16;

  public items: any[] = [
    {
      link: `data/UserClient/newIvitation/${ObjectId.stringObjectId()}`,
      icon: 'group_add',
      // eslint-disable-next-line @typescript-eslint/quotes
      title: "Utilisateur de l'application",
      subtitle: 'Créer des utilisateurs avec envoie de mail'
    }
  ];
  public appName = 'Adaptive-AI';

  constructor(private router: Router) {
    /**
     * Edge wrong drawing fix
     * Navigate anywhere and on Promise right back
     */
    if (/Edge/.test(navigator.userAgent)) {
      if (DashboardComponent.isInitialLoad) {
        void this.router.navigate(['/apps/chat']).then(() => {
          void this.router.navigate(['/']);
        });

        DashboardComponent.isInitialLoad = false;
      }
    }
  }
}
