import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import screenfull from 'screenfull';

@Component({
  selector: 'fury-toolbar-fullscreen-toggle',
  templateUrl: './toolbar-fullscreen-toggle.component.html',
  styleUrls: ['./toolbar-fullscreen-toggle.component.scss']
})
export class ToolbarFullscreenToggleComponent implements OnInit {
  isFullscreen = false;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    screenfull.on('change', () => this.setFullscreen(screenfull.isFullscreen));
  }

  toggleFullscreen(): void {
    if (screenfull.isEnabled) {
      void screenfull.toggle();
      this.setFullscreen(screenfull.isFullscreen);
    }
  }

  setFullscreen(isFullscreen: boolean): void {
    this.isFullscreen = isFullscreen;
    this.cd.markForCheck();
  }
}
