<fury-page-layout mode="card" [formGroup]="allUserformGroup">
  <fury-page-layout-header>
    <fury-breadcrumbs [crumbs]="['Creation des utilisateurs']" [current]="currentEditName"></fury-breadcrumbs>
  </fury-page-layout-header>
  <fury-page-layout-content [fxLayoutGap]="gap" fxLayout="column">
    <fury-card>
      <fury-card-header>
        <fury-card-header-heading>Nouveau Utilisateur</fury-card-header-heading>
        <fury-card-header-subheading>Creation des utilisateur pour le site avec envoie de mail d'invitation</fury-card-header-subheading>

        <fury-card-header-actions>
          <button mat-fab color="primary" *ngIf="allUserformGroup.valid && users.length !== 0" (click)="save()">
            <mat-icon aria-label="add icon">save</mat-icon>
          </button>
          <button class="copy" mat-fab (click)="addUser()">
            <mat-icon aria-label="add icon">add</mat-icon>
          </button>
        </fury-card-header-actions>
      </fury-card-header>
      <fury-card-content>
        <div formArrayName="users" class="userForm">
          <fury-card *ngFor="let userform of users.controls; let i = index" class="user">
            <fury-card-content>
              <app-ui-form-userclientinvitation [formGroup]="userform"></app-ui-form-userclientinvitation>
            </fury-card-content>
          </fury-card>
        </div>
      </fury-card-content>
      <fury-card-content *ngIf="invitationsResult">
        <div *ngIf="invitationsResult.errors?.length"></div>

        <mat-chip-listbox *ngIf="invitationsResult.result?.length" aria-label="Erreur de creation">
          <mat-chip-option *ngFor="let user of invitationsResult.result" selected>{{ user.email }}</mat-chip-option>
        </mat-chip-listbox>

        <mat-chip-listbox *ngIf="invitationsResult.errors?.length" aria-label="Erreur de creation">
          <mat-chip-option *ngFor="let err of invitationsResult.errors" color="warn">{{ err }}</mat-chip-option>
        </mat-chip-listbox>
      </fury-card-content>
    </fury-card>
  </fury-page-layout-content>
</fury-page-layout>
