import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { PublicConfService } from './public-conf.service';
import { ApiHelper } from '@leandredev/common-ng16/httpClient';
import { RunTimeDataService } from '@leandredev/common-ng16/infraService';
import { environment } from 'src/environments/environment';
import { Buffer } from 'buffer';

const JWT_TOKKEN_STORAGE_KEY = environment.application_instance + 'jwToken';
const JWT_EXP_STORAGE_KEY = environment.application_instance + 'jwExp';
const AUTH_REFRESH_TOKKEN_URL = environment.data.authServiceUrl + 'refreshToken';

interface I_Claim {
  sub: string;
  exp: number;
}

interface I_Tokken {
  token: string;
  token_exp: number;
  token_iat: number;
}
@Injectable({
  providedIn: 'root'
})
export class SignInJwtService {
  protected sessionId: string;
  protected httpSub?: Subscription;
  protected sub: string;

  constructor(
    protected runTimeDataService: RunTimeDataService,
    protected http: HttpClient,
    protected publicConfService: PublicConfService
  ) {
    this.sessionId = Math.random().toString();
    const expStr: string = localStorage.getItem(JWT_EXP_STORAGE_KEY);
    const idTokenStr: string = localStorage.getItem(JWT_TOKKEN_STORAGE_KEY);

    if (idTokenStr && expStr && Number(expStr) * 1000 > Date.now()) {
      this.connect(idTokenStr);
    } else {
      this.disconnect();
    }
  }

  protected _isConnecting = false;

  public get isConnecting(): boolean {
    return this._isConnecting;
  }

  protected unAuthorizedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public unAuthorizedObs: Observable<boolean> = this.unAuthorizedSubject.asObservable();

  protected connectedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public connectedObs: Observable<boolean> = this.connectedSubject.asObservable();

  public get isConnected(): boolean {
    return this.connectedSubject.value;
  }

  protected token: string;

  public connect(jwt: string): Observable<null> {
    if (this._isConnecting) {
      throw new Error('Connecion is started');
    }

    if (!jwt) {
      throw new Error('No idToken');
    }

    if (this.connectedSubject.value) {
      this.disconnect();
    }

    this._isConnecting = true;

    const claim: I_Claim = JSON.parse(Buffer.from(jwt.split('.')[1], 'base64').toString('utf-8')) as I_Claim;

    this.sub = claim.sub;
    localStorage.setItem(JWT_TOKKEN_STORAGE_KEY, jwt);
    localStorage.setItem(JWT_EXP_STORAGE_KEY, String(Number(claim.exp)));

    this.token = jwt;

    const storeValue = {
      token: jwt,
      sessonId: this.sessionId,
      userId: this.sub
    };

    // BUG
    // À la connexion à l'application
    // signIn-jwt.service.ts:95 ERROR error : Argument passed in must be a single String of 12 bytes or a string of 24 hex characters
    // this.runTimeDataService.updateStore
    this.runTimeDataService.updateStore('token', storeValue);

    this.runTimeDataService.updateStore('ConfService', claim);

    if (claim.exp) {
      const tokenDuration: number = (claim.exp - 30) * 1000 - Date.now();

      if (tokenDuration > 0) {
        window.setTimeout(() => {
          this.refreshToken();
        }, tokenDuration);
      } else {
        this.refreshToken();
      }
    }
    if (this.unAuthorizedSubject.value) {
      this.unAuthorizedSubject.next(false);
    }

    this.connectedSubject.next(true);
    this._isConnecting = false;

    return of();
  }

  public disconnect(): void {
    if (this._isConnecting) {
      throw new Error('connexion is started');
    }

    this.sub = null;

    localStorage.removeItem(JWT_TOKKEN_STORAGE_KEY);
    localStorage.removeItem(JWT_EXP_STORAGE_KEY);
    localStorage.removeItem(AUTH_REFRESH_TOKKEN_URL);

    this.runTimeDataService.updateStore('token', null);
    this.runTimeDataService.updateStore('ConfService', null);

    this.connectedSubject.next(false);
  }

  public refreshToken(): void {
    if (!this.connectedSubject.value) {
      return;
    }

    const headers = new HttpHeaders();
    headers.set('Accept', 'application/json').set('Content-Type', 'application/json').set('JWT', this.token);

    const options = { headers };

    if (this.httpSub) {
      this.httpSub.unsubscribe();
    }

    this.httpSub = this.http
      .get(AUTH_REFRESH_TOKKEN_URL, options)
      .pipe(ApiHelper.resultToArr(`Err of ${AUTH_REFRESH_TOKKEN_URL}`))
      .subscribe({
        next: (data: I_Tokken[]) => {
          if (data.length === 0) {
            this.disconnect();
            return;
          }

          const conf = data[0];
          this.token = conf.token;

          this.runTimeDataService.updateStore('token', {
            token: this.token,
            sessonId: this.sessionId,
            userId: this.sub
          });

          const tokenDuration: number = (conf.token_exp - 30) * 1000 - Date.now();

          if (tokenDuration > 0) {
            window.setTimeout(() => {
              this.refreshToken();
            }, tokenDuration);
          } else {
            this.refreshToken();
          }
        },
        error: (err) => {
          console.warn(err);
          this.disconnect();
        }
      });
  }
}
